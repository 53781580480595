import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Navigation Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/navigation/"
    metaDescription="Make sure your design is efficient and easy to use with Uizard's pre-made navigation UI component templates built with best practices in mind."
    description="
    h2:Find the best nav bar for your projects
    <br/>
    Whether you're designing an app or a website, you can drag-and-drop customizable navigation UI components to work seamlessly with your design. They say navigation is the heart of every app or web design. Make sure it's efficient and easy to use with Uizard's pre-made navigation component template built with best practices in mind.
    <br/>
    h3:Customizable navigation templates that are simple to adjust
    <br/>
    Navigation is an often overlooked element of mobile UI/UX but is arguably a user's favorite feature on your website or app. Luckily, Uizard has built-in <a:https://uizard.io/templates/component-templates/>UI components</a> that you can use and customize in order to create elegant, intuitive designs that work seamlessly across devices.
    <br/>
    h3:Incorporate navigation into your design, without the complexities
    <br/>
    Uizard's navigation components feature cool, customizable elements that let you come up with professional design without the technicalities. Perfect for designing mockups or <a:https://uizard.io/prototyping/>UI prototyping</a>, explore the range now.
    "
    pages={[
      "Thick app nav bar",
      "Simple top nav bar",
      "Web app header",
      "Side panel icons",
      "Footer work message app",
      "App header and stories",
      "Simple app header",
      "App hero with icons",
      "App top nav search",
      "Email client side bar",
      "Web app top bar",
      "Web shop footer",
      "Settings menu",
      "App nav with back button",
      "Tabs",
      "App header with title",
      "Asymmetric app footer",
      "App footer overlay button",
      "Footer app nav icons and text",
      "Footer app nav icons",
      "Vertical nav side panel",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing navigations"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing navigations"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing navigations"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing navigations"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing navigations"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/nav/nav_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/nav/nav_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/nav/nav_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/nav/nav_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/nav/nav_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
